/* eslint-disable indent */
/* eslint-disable comma-dangle */
/* eslint-disable no-trailing-spaces */

type StaticImages = {
    readonly [fileName: string]: {
        uri?: string;
    };
}

const imageStaticSourcesByFileName: StaticImages = {
    'i8Pxkgp3ND1BRkyXX6sSJd.png': { uri: '/images/i8Pxkgp3ND1BRkyXX6sSJd.png' },
'qNttpN9RhM3rogtnag6Wkp.jpg': { uri: '/images/qNttpN9RhM3rogtnag6Wkp.jpg' },
'vrTi3K1aEkZmqKCCe57VES.png': { uri: '/images/vrTi3K1aEkZmqKCCe57VES.png' },
'nFXG1YUKra3P4QorxTfdxD.png': { uri: '/images/nFXG1YUKra3P4QorxTfdxD.png' },
'rPcEgMe5nDqE9gkXCGhT7g.png': { uri: '/images/rPcEgMe5nDqE9gkXCGhT7g.png' },
'7JFbfiFyqQPyEkVdfCydfg.png': { uri: '/images/7JFbfiFyqQPyEkVdfCydfg.png' },
'pkoED4HgcvsyWDZJmHvegZ.png': { uri: '/images/pkoED4HgcvsyWDZJmHvegZ.png' },
'gCvAGNRvu2NLQzuiXJdvjL.png': { uri: '/images/gCvAGNRvu2NLQzuiXJdvjL.png' },
'b5jdwJR7a8LEoLGasSunjd.png': { uri: '/images/b5jdwJR7a8LEoLGasSunjd.png' },
'ovyF5LtX8mdRogL7hZcGYU.png': { uri: '/images/ovyF5LtX8mdRogL7hZcGYU.png' },
'bXNs2EmEwJD6n3m6H3qy7Y.png': { uri: '/images/bXNs2EmEwJD6n3m6H3qy7Y.png' },
'8rVfebvjB2BN3GVt9LHXzJ.png': { uri: '/images/8rVfebvjB2BN3GVt9LHXzJ.png' },
'7sctu29pjE5aM4HvWxeYqE.png': { uri: '/images/7sctu29pjE5aM4HvWxeYqE.png' },
'pZsN21K11MfwH8Sdgoup1G.png': { uri: '/images/pZsN21K11MfwH8Sdgoup1G.png' },
'nG3UyNJnbVAxndzRyrCXSW.png': { uri: '/images/nG3UyNJnbVAxndzRyrCXSW.png' },
'hG2LausqfpGz3q3nQF4Get.png': { uri: '/images/hG2LausqfpGz3q3nQF4Get.png' },
'4kuhCv5ucDUs5YfwbDqNeZ.png': { uri: '/images/4kuhCv5ucDUs5YfwbDqNeZ.png' },
'hqpk2wiB5Dfhhrp7LeuyUk.jpg': { uri: '/images/hqpk2wiB5Dfhhrp7LeuyUk.jpg' },
'7hG6zEUn2ZnJSpEt4rm8eY.png': { uri: '/images/7hG6zEUn2ZnJSpEt4rm8eY.png' },
'ccNojYtLyA7ebPhNSFweUX.png': { uri: '/images/ccNojYtLyA7ebPhNSFweUX.png' },
'fQQe3ih1BgGaBoc5Mgy9p3.png': { uri: '/images/fQQe3ih1BgGaBoc5Mgy9p3.png' },
'nryjJZxrRBs4UL5rSz72MS.jpg': { uri: '/images/nryjJZxrRBs4UL5rSz72MS.jpg' },
'6xPYnEUo3J1Zs2iSirJXoB.jpg': { uri: '/images/6xPYnEUo3J1Zs2iSirJXoB.jpg' },
'99ffMEbHCMaV7VdWg7UUzt.jpg': { uri: '/images/99ffMEbHCMaV7VdWg7UUzt.jpg' },
'oNDSaZy1LeGd3ZnGJDWwnx.jpg': { uri: '/images/oNDSaZy1LeGd3ZnGJDWwnx.jpg' },
'1pMWFVpwRMBoYMXhPqkzZE.png': { uri: '/images/1pMWFVpwRMBoYMXhPqkzZE.png' },
'px9C7HWgNxMj5iXQcsAUUU.jpg': { uri: '/images/px9C7HWgNxMj5iXQcsAUUU.jpg' },
'kK1j3ztbeeigKD8cmoBkd6.jpg': { uri: '/images/kK1j3ztbeeigKD8cmoBkd6.jpg' },
'mcPopRpCZuShwWh51dEDT6.jpg': { uri: '/images/mcPopRpCZuShwWh51dEDT6.jpg' }
}

export default imageStaticSourcesByFileName
